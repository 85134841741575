import { createRouter, createWebHistory } from "vue-router";
import BlankLayout from "@/layouts/BlankLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import favicon from '@/assets/images/services/fev-icon.png';

const routes = [
  {
    path: "/",
    alias: "/home",
    name: "home",

    meta: {
      title: "Home",
      layout: MainLayout,
    },
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {  
      title: "About",
      layout: MainLayout,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AboutView.vue"),
  },


  {
    path: "/aboutnew",
    name: "aboutnew",
    meta: {
      title: "About New",
      layout: MainLayout,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Aboutnew.vue"),
  },
  
  {
    path: "/services",
    name: "services",
    meta: {
      title: "Services",
      layout: MainLayout,
    },
    component: () => import("@/views/ServicesView.vue"),
  },

  {
    path: "/consultancy",
    name: "consultancy",
    meta: {
      title: "Consultancy",
      layout: MainLayout,
    },
    component: () => import("@/views/ConsultancyView.vue"),
  },

  {
    path: "/appointment",
    name: "appointment",
    meta: {
      title: "Consultancy",
      layout: MainLayout,
    },
    component: () => import("@/views/AppointmentView.vue"),
  },

  {
    path: "/documents",
    name: "documents",
    meta: {
      title: "Documents",
      layout: MainLayout,
    },
    component: () => import("@/views/DocumentsView.vue"),
  },

  {
    path: "/procurement",
    name: "procurement",
    meta: {
      title: "Procurement",
      layout: MainLayout,
    },
    component: () => import("@/views/ProcurementView.vue"),
  },

  {
    path: "/getquote",
    name: "getquote",
    meta: {
      title: "Quotation",
      layout: MainLayout,
    },
    component: () => import("@/views/GetQuoteView.vue"),
  },

  {
    path: "/workform",
    name: "workform",
    meta: {
      title: "Workform",
      layout: MainLayout,
    },
    component: () => import("@/views/WorkFormView.vue"),
  },

  {
    path: "/transcripts/apply",
    name: "transcripts",
    meta: {
      title: "Transcript Application",
      layout: MainLayout,
    },
    component: () => import("@/views/ApplyTranscriptView.vue"),
  },

  //   {
  //     path: "/attestation-of-birth",
  //     name: "attestation-of-birth",
  //     meta: {
  //       title: "Birthday Certificate Application",
  //       layout: MainLayout,
  //     },
  //     component: () => import("@/views/AttestationOfBirthView.vue"),
  //   },

  {
    path: "/birth-certificate/",
    name: "birth-certificate",
    meta: {
      title: "Birthday Certificate Application",
      layout: MainLayout,
    },
    component: () => import("@/views/BirthCertificateView.vue"),
  },

  {
    path: "/certificate-incorporate/",
    name: "certificate-incorporate",
    meta: {
      title: "Certificate Of Incorporate Application",
      layout: MainLayout,
    },
    component: () => import("@/views/CertificateOfIncorporateView.vue"),
  },

  {
    path: "/faq/",
    name: "faqs",
    meta: {
      title: "FAQs",
      layout: MainLayout,
    },
    component: () => import("@/views/faqView.vue"),
  },
  {
    path: "/404",
    name: "NotFound",
    meta: {
      title: "Page Not Found",
      layout: BlankLayout,
    },
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    // Route does not exist, redirect to the 404 page
    next({ name: "NotFound", replace: true });
  } else {
    const title = to.meta.title;
    document.title = `E-DOCNG - ${title}`;
      // Create a link element for the favicon
      const faviconLink = document.querySelector("link[rel='icon']") || document.createElement('link');
      faviconLink.href = favicon; // Use a relative path
      faviconLink.rel = 'icon';
      document.head.appendChild(faviconLink)
    next();
  }  
});  
     
export default router;  
